// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dyrequiz-js": () => import("./../../../src/pages/dyrequiz.js" /* webpackChunkName: "component---src-pages-dyrequiz-js" */),
  "component---src-pages-film-quiz-js": () => import("./../../../src/pages/film-quiz.js" /* webpackChunkName: "component---src-pages-film-quiz-js" */),
  "component---src-pages-gaming-quiz-js": () => import("./../../../src/pages/gaming-quiz.js" /* webpackChunkName: "component---src-pages-gaming-quiz-js" */),
  "component---src-pages-geografi-quiz-js": () => import("./../../../src/pages/geografi-quiz.js" /* webpackChunkName: "component---src-pages-geografi-quiz-js" */),
  "component---src-pages-historie-quiz-js": () => import("./../../../src/pages/historie-quiz.js" /* webpackChunkName: "component---src-pages-historie-quiz-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-julequiz-js": () => import("./../../../src/pages/julequiz.js" /* webpackChunkName: "component---src-pages-julequiz-js" */),
  "component---src-pages-kahoot-sporsmal-js": () => import("./../../../src/pages/kahoot-sporsmal.js" /* webpackChunkName: "component---src-pages-kahoot-sporsmal-js" */),
  "component---src-pages-kjendis-quiz-js": () => import("./../../../src/pages/kjendis-quiz.js" /* webpackChunkName: "component---src-pages-kjendis-quiz-js" */),
  "component---src-pages-lett-blanding-quiz-js": () => import("./../../../src/pages/lett-blanding-quiz.js" /* webpackChunkName: "component---src-pages-lett-blanding-quiz-js" */),
  "component---src-pages-litteratur-quiz-js": () => import("./../../../src/pages/litteratur-quiz.js" /* webpackChunkName: "component---src-pages-litteratur-quiz-js" */),
  "component---src-pages-mine-sporsmal-js": () => import("./../../../src/pages/mine-sporsmal.js" /* webpackChunkName: "component---src-pages-mine-sporsmal-js" */),
  "component---src-pages-musikk-quiz-js": () => import("./../../../src/pages/musikk-quiz.js" /* webpackChunkName: "component---src-pages-musikk-quiz-js" */),
  "component---src-pages-norsk-blandet-quiz-js": () => import("./../../../src/pages/norsk-blandet-quiz.js" /* webpackChunkName: "component---src-pages-norsk-blandet-quiz-js" */),
  "component---src-pages-oslo-quiz-js": () => import("./../../../src/pages/oslo-quiz.js" /* webpackChunkName: "component---src-pages-oslo-quiz-js" */),
  "component---src-pages-politikk-quiz-js": () => import("./../../../src/pages/politikk-quiz.js" /* webpackChunkName: "component---src-pages-politikk-quiz-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-quiz-for-barn-js": () => import("./../../../src/pages/quiz-for-barn.js" /* webpackChunkName: "component---src-pages-quiz-for-barn-js" */),
  "component---src-pages-sportsquiz-js": () => import("./../../../src/pages/sportsquiz.js" /* webpackChunkName: "component---src-pages-sportsquiz-js" */),
  "component---src-pages-stor-geografi-quiz-js": () => import("./../../../src/pages/stor-geografi-quiz.js" /* webpackChunkName: "component---src-pages-stor-geografi-quiz-js" */),
  "component---src-pages-tv-quiz-js": () => import("./../../../src/pages/tv-quiz.js" /* webpackChunkName: "component---src-pages-tv-quiz-js" */),
  "component---src-pages-vitenskap-quiz-js": () => import("./../../../src/pages/vitenskap-quiz.js" /* webpackChunkName: "component---src-pages-vitenskap-quiz-js" */),
  "component---src-templates-basic-js": () => import("./../../../src/templates/basic.js" /* webpackChunkName: "component---src-templates-basic-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */)
}

